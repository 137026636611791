.app-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}

.loader-spin {
  text-align: center;
  margin-top: 100vh;
}
.loader {
  position: relative;
  width: 140px;
  height: 140px;
}

.loader svg {
  position: absolute;
  top: 0;
  left: 0;
}

.loader svg circle {
  fill: none;
  stroke: #fff;
  stroke-width: 10px;
  stroke-linecap: round;
  animation: animate 2s linear infinite;
}

.loader svg:nth-child(1) circle {
  filter: blur(10px);
  stroke: #ffcb08;
}

.loader svg:nth-child(2) circle {
  filter: blur(20px);
  stroke: #ffcb08;
}

@keyframes animate {
  0% {
    stroke-dasharray: 295;
    stroke-dashoffset: 295;
  }
  50% {
    stroke-dasharray: 295;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 295;
    stroke-dashoffset: -295;
  }
}